import React, { useState, useEffect } from "react";
// components
import Navbar from "../../components/navbar.js";
import Hero from "../../components/hero.js";
import View from "../../components/view.js";
import Footer from "../../components/footer";
import Sidebar from "../../components/sidebar";
import Modal from "../../components/modal";
// Images
import Logo_ecoAD from "../../../static/images/ecoad-Logo.svg";
import Siegel_ecoAD from "../../../static/images/mockup_ecoad_screens.jpg";
import Logo_iq from "../../../static/images/iq_black.svg";
import Image_hero from "../../../static/images/undraw_online_ad_re_ol62.svg";
import Image_view1 from "../../../static/images/undraw_heartbroken_cble.svg";
import Image_view5 from "../../../static/images/undraw_mindfulness_scgo.svg";
import Image_contact from "../../../static/images/undraw_contact_us_15o2.svg";
import Button from "../../components/button.js";
import en from "../../i18n/en.js";

const Home = () => {
    const [sidebarActive, setSidebarActive] = useState(false);
    const [infoModalActive, setInfoModalActive] = useState(false);
    const [scrollbarWidth, setScrollbarWidth] = useState("15px");
    const onHamburgerClickHandler = (e) => {
        setSidebarActive(true);
    };
    const onSidebarCloseClickHandler = (e) => {
        setSidebarActive(false);
    };
    useEffect(() => {
        if (sidebarActive || infoModalActive) {
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = scrollbarWidth;
            document.querySelector("#navbar").style.paddingRight = scrollbarWidth;
        }
        else {
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.overflowX = "hidden";
                document.body.style.paddingRight = "inherit";
                document.querySelector("#navbar").style.paddingRight = "inherit";
            }, 300);
        }
    }, [sidebarActive, infoModalActive, scrollbarWidth]);
    useEffect(() => {
        let width = window.innerWidth - document.documentElement.clientWidth + "px";
        document.body.style.overflowX = "hidden";
        setScrollbarWidth(width);

        Array.from(document.querySelectorAll('a[href^="#"]')).forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                setSidebarActive(false);
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }, []);
    return (
        <div style={(sidebarActive || infoModalActive) ? { overflow: "hidden" } : {}} className="relative mx-auto">
            <Sidebar
                active={sidebarActive}
                onCloseClick={onSidebarCloseClickHandler}
                lists={en.footer.lists}
            />
            <Modal
                active={infoModalActive}
                onBackgroundClick={(e) => setInfoModalActive(false)}
            />
            <Navbar
                logo_ecoAD={Logo_ecoAD}
                logo_iq={Logo_iq}
                onHamburgerClick={onHamburgerClickHandler}
                sidebarActive={sidebarActive}
                padding={scrollbarWidth}
            />
            <main>
                <Hero
                    image={Image_hero}
                    imageLeft={false}
                    data={en.hero}
                />
                <View
                    image={Image_view1}
                    title={en.view1.title}
                    header={en.view1.header}
                    content={en.view1.content}
                    imageRight={false}
                />
                <View
                    image={Siegel_ecoAD}
                    title={en.view2.title}
                    header={en.view2.header}
                    imageRight={true}
                >
                    <p className="text-lg">
                        {en.view2.paragraph1}
                    </p>
                    <ul className="ml-5 mt-5 list-disc">
                        <li className="my-2"><span className="font-bold">eco ads® </span>{en.view2.li1}</li>
                        <li className="my-2"><span className="font-bold">eco ads® </span>{en.view2.li2}</li>
                    </ul>
                    <p>
                        <b>eco ads®</b> {en.view2.paragraph2}
                    </p>
                    <p>
                        {en.view2.paragraph3}
                    </p>
                </View>
                <View
                    image={Image_view5}
                    title={en.view5.title}
                    header={en.view5.header}
                    content={en.view5.content}
                    imageRight={false}
                />
            </main>
            <View
                image={Image_contact}
                title={en.contact.title}
                header={en.contact.header}
                content={en.contact.content}
                imageRight={true}
            >
                <div className="xl:flex xl:justify-around xl:items-center" style={{ alignItems: "flex-start", flexDirection: "column" }}>
                    <div className="my-3 full-size">
                        <Button
                            text={en.contact.button1Text}
                            link={en.contact.button1Link}
                            target={"_blank"}
                            className="my-3"
                        />
                    </div>
                    <div className="my-3 full-size">
                        <Button
                            text={en.contact.button2Text}
                            link={en.contact.button2Link}
                            target={"_blank"}
                            className="my-3"
                        />
                    </div>
                    <div className="my-3 full-size" >
                        <Button
                            text={en.contact.button3Text}
                            link={en.contact.button3Link}
                            target={"_blank"}
                            className="my-3"
                        />
                    </div>
                </div>
            </View>
            <Footer
                data={en.footer}
            />
        </div>
    );
};

export default Home;

const en = {
  hero: {
    title: "Hero",
    header: "%% Digital advertising%% that protects the environment!",
    content: `Great to have you here! Whether you landed on this page by chance or on purpose: You are in the right place!

  Whether it's a Sunday newspaper, billboard, or digital ad: Advertising is resource-intensive. As a digital marketer, we take responsibility for our products and their effects. We want to make digital advertising more sustainable. Our result? The %% eco ads®%%.

  Learn more now about the CO2-reduced advertising mediums from iq digital.`,
    image: "undraw_online_ad_mg4t.svg"
  },
  view1: {
    title: "reduceEmission",
    header: "Did you know?",
    content: `Delivering digital advertising consumes electricity and causes corresponding CO2 emissions.

 An example: An average digital media campaign generates comparable greenhouse gas emissions to a flight from Düsseldorf to Hawaii - and back.

 In fact, the share of the entire media industry in global total emissions is 3 to 4 percent, putting it on the same level as global aviation.

 To significantly reduce the CO2 footprint of media campaigns, we developed the %% eco ads®%%.`,
    image: "undraw_Nature_benefits_re_kk70.svg"
  },
  view2: {
    title: "reduce",
    header: "%%eco ads®%% reduce the amount of CO2 emitted per ad delivery.",
    paragraph1: "We have optimized at every corner and achieved significant CO2 savings:",
    paragraph2: "are embedded in a CO2-optimized technological setup.",
    paragraph3: "iq digital is not only committed to climate protection but also keeps social and societal aspects in view. For this, we were awarded the bronze medal by Eco Vadis in August 2024.",
    li1: "have a significantly smaller file size - up to 84 % less, depending on the ad format.",
    li2: "are reduced to a necessary level of tracking. When tracking an ad, specific technologies are used to track how many people, among other things, saw or clicked on the ad. With our eco ads®, only what is absolutely essential is measured. We avoid long scripts, reduce data, and save energy.",
  },
  view5: {
    title: "why",
    header: "What can you do to support sustainability in digital advertising?",
    content: "Every time you see a digital ad carrying the %%eco ad®%% label, you can be sure: The advertising company is taking ecological responsibility!\n Want to get more involved yourself? Choose digital media that offer high-quality content. You can recognize these by the fact that they are not overloaded with ads and have corresponding journalistic quality.",
  },
  contact: {
    title: "Contact us.",
    header: "Do you have an advertising message you want to spread digitally?",
    content: `With our %% eco ads®%%, you can market your advertising message much more sustainably - cross-digital in a value media environment. And all this without sacrificing creative freedom.

 %% eco ads®%% are also an economically sensible alternative to conventional advertising media: The pricing is just as convincing as the quality.

 Have we piqued your interest? We would be happy to advise you!`,
    button1Link: "https://www.iqdigital.de/en/Ueber-uns/Kontakt",
    button1Text: "Contact",
    button2Link: "/files/eco_ad_by_iq_digital_eng.pdf",
    button2Text: "More information",
    button3Link: "https://www.iqdigital.de/en/Produkte/Digital/werbemittel-showroom",
    button3Text: "Ad Showroom",
  },
  footer: {
    content: `At iq digital, we want to revolutionize the digital advertising market with eco ads®.
 Instead of overloaded ad formats, we have the vision to create a win-win situation for customers and users with a reduced approach.
 At the same time, we want to help you reduce your digital ecological footprint.

 We focus on transparency and sustainability in all our processes.
 Even in our communication, we pay attention to sustainability: This page is optimized for minimal file sizes and avoids any type of analytics.
 Moreover, the hosting server of the website gets its electricity 100% from renewable energy sources.`,
    lists: [
      {
        header: "eco ad®",
        items: [
          {
            link: "#view_reduceEmission",
            text: "Environment"
          },
          {
            link: "#view_reduce",
            text: "Savings"
          },
          {
            link: "#view_why",
            text: "Why eco ad®?"
          },
        ]
      },
      {
        header: "Links",
        items: [
          {
            link: "https://www.iqdigital.de/en",
            text: "iq digital"
          },
          {
            link: "/files/eco_ad_by_iq_digital_eng.pdf",
            text: "Book eco ads®"
          },
          {
            link: "https://www.iqdigital.de/en/Ueber-uns/Impressum",
            text: "Imprint"
          },
          {
            link: "https://www.iqdigital.de/en/Ueber-uns/Kontakt",
            text: "Contact"
          },
        ]
      },
    ]
  }
};

export default en;
